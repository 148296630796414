import React from 'react';
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Link,
    Badge
} from '@mui/material';
import StarBorder from '@mui/icons-material/StarBorder';
import RoundedButton from '@components/Buttons/RoundedButton';
import { COLORS } from '@colors/index';
import { Links } from '@links/index';
import { NavLink } from 'react-router-dom';

const Pricing: React.FunctionComponent = () => {
    return (
        <Box>
            <Grid container py={12}>
                <Container maxWidth="sm">
                    <Typography variant="h3" align="center" color="text.primary" gutterBottom>
                        Nightly, weekly, and monthly sites
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" component="p">
                        Sun Creek Point offers an experience to remember. Sites are going fast, so book your stay today!
                    </Typography>
                </Container>
            </Grid>
            <Grid container mb={12}>
                <Container maxWidth="md">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Nightly"
                                    titleTypographyProps={{ align: 'center' }}
                                    sx={{ backgroundColor: COLORS.LightGray }}
                                />
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                                        <Box borderRadius={4} mr={1}>
                                            <Typography fontSize={28} sx={{ textDecoration: 'line-through' }}>
                                                $129*
                                            </Typography>
                                        </Box>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $89*
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /per day
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle1" align="center">
                                        Rates may vary based on site, date, and time of reservation. A deposit is required for all reservations.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link
                                        component={NavLink}
                                        to="/contact"
                                        style={{ textDecoration: 'none', width: '100%' }}
                                    >
                                        <RoundedButton fullWidth variant="outlined">
                                            Contact Us
                                        </RoundedButton>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Monthly"
                                    titleTypographyProps={{ align: 'center' }}
                                    action={<StarBorder />}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    sx={{ backgroundColor: COLORS.LightGray }}
                                />
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $950
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /per month + Electricity
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle1" align="center">
                                        Rates may vary based on site, date, and time of reservation. A deposit is required for all reservations.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link
                                        href={Links.ReserveLink}
                                        target="_blank"
                                        style={{ textDecoration: 'none', width: '100%' }}
                                    >
                                        <RoundedButton fullWidth variant="contained">
                                            Reserve
                                        </RoundedButton>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Box>
    );
};
export default Pricing;

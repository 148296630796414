import { Box, Container, Grid, Stack, Typography, Link, List, ListItem } from '@mui/material';
import RoundedButton from '@components/Buttons/RoundedButton';
import { COLORS } from '@colors/index';
import { Links } from '@links/index';
import { motion } from 'framer-motion';
import SectionFeature from '@components/SectionFeature';
const Home: React.FunctionComponent = () => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
            <Box>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '100vh',
                        width: '100%'
                    }}
                >
                    <Box
                        sx={{
                            backgroundImage: 'url(/img/backgrounds/homeBackground.jpg)',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            height: '100vh',
                            width: '100vw',
                            filter: 'brightness(15%)',
                            zIndex: -1
                        }}
                    />
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={7}>
                                <Typography
                                    fontWeight={800}
                                    color={COLORS.White}
                                    sx={{
                                        fontSize: { xs: 22, sm: 45, md: 60 },
                                        textAlign: { xs: 'center', md: 'left' }
                                    }}
                                >
                                    Treasure Valley’s Premier Luxury RV Resort
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        <Link
                                            href={Links.ReserveLink}
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <RoundedButton variant="contained">Reserve</RoundedButton>
                                        </Link>
                                    </Grid>
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        <Link
                                            href={Links.SeasonalInquiry}
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <RoundedButton variant="contained">Seasonal Inquiry</RoundedButton>
                                        </Link>
                                    </Grid>
                                    <Grid
                                        item
                                        display="flex"
                                        justifyContent="center"
                                        sx={{ width: { xs: '100%', md: 'auto' } }}
                                    >
                                        <Link
                                            href={Links.JobApplicationLink}
                                            target="_blank"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <RoundedButton variant="contained">Job Application</RoundedButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 1.5,
                        type: 'spring',
                        bounce: 0.3
                    }}
                    viewport={{
                        amount: 0.5
                    }}
                >
                    <Grid container bgcolor={COLORS.Primary} py={6} px={2}>
                        <Container maxWidth="md">
                            <Typography
                                sx={{
                                    fontSize: { xs: 18, sm: 20, md: 25 }
                                }}
                                textAlign="center"
                                mb={6}
                            >
                                Experience unparalleled luxury at Sun Creek Point, the newest and finest park in the
                                Treasure Valley. Nestled in a tranquil neighborhood, Sun Creek Point offers exceptional
                                indoor and outdoor amenities:
                            </Typography>
                            <Grid container alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Stack alignItems="center" textAlign="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: 18, sm: 20, md: 22 }
                                            }}
                                            fontWeight={600}
                                        >
                                            Outdoor
                                        </Typography>
                                        <List
                                            sx={{
                                                fontSize: { xs: 14, sm: 16, md: 18 },
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside'
                                            }}
                                        >
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Spacious sites up to 90’ long and 35’ wide
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Large backyards</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Over 5 acres of landscaping
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>4 dog parks</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Patio Sites with backyards and fencing
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Multiple shaded sitting areas
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Extra-wide streets</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Exclusive 18+ area</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Hot Tub</ListItem>
                                        </List>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack alignItems="center" textAlign="center" spacing={2}>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: 18, sm: 20, md: 22 }
                                            }}
                                            fontWeight={600}
                                        >
                                            Indoor
                                        </Typography>
                                        <List
                                            sx={{
                                                fontSize: { xs: 14, sm: 16, md: 18 },
                                                listStyleType: 'disc',
                                                listStylePosition: 'inside'
                                            }}
                                        >
                                            <ListItem sx={{ display: 'list-item' }}>
                                                10,000 Square foot clubhouse
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                2,500 square foot clubhouse
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Billiards Room</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Multiple sitting rooms</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                14 Private bathrooms with tiled showers
                                            </ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>2 laundry rooms</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Golf simulator room</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Giant indoor sports court</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Pickleball courts</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>Workout gym</ListItem>
                                            <ListItem sx={{ display: 'list-item' }}>
                                                Commercial-grade dog wash station
                                            </ListItem>
                                        </List>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Grid>
                </motion.div>
                <Grid container my={12}>
                    <SectionFeature
                        title="Site Amenities"
                        descriptionList={[
                            '225 Sites on 30 acres',
                            '65’, 80’ &amp; 90’ long sites and 30’ &amp; 35’ wide',
                            'Pull through, Patio Sites and back-in sites',
                            '30’ wide streets',
                            'Various site designs including patio sites, privacy fencing, artificial turf backyards',
                            'Massive backyards with amazing landscaping',
                            '18 and older section with its own clubhouse and dog parks'
                        ]}
                        image="/img/home/map.jpg"
                    />
                </Grid>
                <Grid container my={12}>
                    <SectionFeature
                        title="Location"
                        description="Conveniently located off I-84 exit 29 in Caldwell. Located near schools, shopping,
                                restaurants, and just 5 minutes from the freeway for easy access to the whole Treasure
                                Valley!"
                        image="/img/home/location.jpg"
                        button="Get Directions"
                        link={Links.AddressLink}
                        mirror
                    />
                </Grid>
                <Grid container my={12}>
                    <SectionFeature
                        title="Tour the Site"
                        description="Sun Creek Point boasts not 1 but 2 luxurious clubhouses. The 10,000 square foot main clubhouse will open in the Fall of 2024. It will be equipped with a heated pool that’s open year- round, a 15-person hot tub, a golf simulator room, full-size sports court, game rooms, sunrooms, a laundry room, private bathrooms and showers."
                        description2="The Second clubhouse, located in the 18+ section of the park, has a large gathering room to watch tv and relax and a sunroom to enjoy nature coupled with 6 private bathrooms with showers and a laundry room."
                        image="/img/home/tour.jpg"
                        button="Take a Tour"
                        link="/facilities"
                    />
                </Grid>
            </Box>
        </motion.div>
    );
};
export default Home;
